var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "page-quality-list": true,
        "page-tlj": _vm.fType == 5 && !_vm.isLoss
      }
    },
    [
      _vm.fType != 5 || _vm.isLoss
        ? _c(
            "div",
            { staticClass: "user-header", on: { click: _vm.switchAccount } },
            [
              _c("div", { staticClass: "img-box" }, [
                _c("img", {
                  attrs: { src: _vm.ossHost + "img/logo_min.png", alt: "" }
                })
              ]),
              _c("span", [_vm._v(_vm._s(_vm.name))]),
              _c("span", { staticClass: "switch-account" }, [
                _vm._v("切换账号")
              ])
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          class: {
            "scan-body": true,
            "search-body": _vm.isKzqSearchRes,
            "tlj-body": _vm.fType == 5 && !_vm.isLoss
          }
        },
        [
          _c("div", { staticClass: "scan-header" }, [
            _c(
              "div",
              { staticClass: "scan-search" },
              [
                _c("Search", {
                  attrs: {
                    "show-action": "",
                    background: "#fff",
                    placeholder: "请输入搜索关键词",
                    clearable: true,
                    "clear-trigger": "always",
                    maxlength: _vm.type != 1 ? 8 : 13
                  },
                  on: {
                    search: function($event) {
                      return _vm.onSearch("")
                    },
                    clear: function($event) {
                      _vm.pageNo = 1
                      _vm.loadCheckList()
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "action",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.onSearch("")
                                }
                              }
                            },
                            [_vm._v("搜索")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.value,
                    callback: function($$v) {
                      _vm.value = $$v
                    },
                    expression: "value"
                  }
                }),
                _c("div", { staticClass: "scan-btn" }, [
                  _c("img", {
                    attrs: { src: _vm.ossHost + "img/scan.png", alt: "" },
                    on: { click: _vm.scan }
                  }),
                  _c("img", {
                    staticClass: "icon-help",
                    attrs: {
                      src: _vm.ossHost + "img/icon_description.png",
                      alt: ""
                    },
                    on: { click: _vm.viewDescription }
                  })
                ])
              ],
              1
            ),
            !_vm.isKzqSearchRes && (_vm.fType != 5 || _vm.isLoss)
              ? _c(
                  "div",
                  { staticClass: "scan-tab" },
                  [
                    _c(
                      "Tabs",
                      {
                        on: {
                          click: function($event) {
                            return _vm.changeTab()
                          }
                        },
                        model: {
                          value: _vm.activeIndex,
                          callback: function($$v) {
                            _vm.activeIndex = $$v
                          },
                          expression: "activeIndex"
                        }
                      },
                      [
                        _c("Tab", { attrs: { title: _vm.waitQcCountText } }),
                        _c("Tab", { attrs: { title: "待复核" } })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "scan-list-body" },
            [
              _vm._l(_vm.list, function(item, index) {
                return _c("QualityItem", { key: index, attrs: { item: item } })
              }),
              _c(
                "div",
                { staticClass: "loading-box" },
                [
                  _vm.loading && !_vm.isShowAll
                    ? _c("Button", {
                        attrs: {
                          loading: "",
                          type: "default",
                          "loading-text": "加载中..."
                        }
                      })
                    : _vm._e(),
                  !_vm.loading && !_vm.isShowAll
                    ? _c(
                        "Button",
                        {
                          attrs: { type: "default" },
                          on: { click: _vm.loadMore }
                        },
                        [_vm._v("点击加载更多")]
                      )
                    : _vm._e(),
                  _vm.isShowAll &&
                  _vm.type == 1 &&
                  !_vm.list.length &&
                  _vm.isKzqSearchRes
                    ? _c("div", { staticClass: "empty-box" }, [
                        _c("p", [
                          _vm._v("未找到对应订单，请点击下方按钮手动新建")
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "btn",
                            on: { click: _vm.jumpCreateOrderPage }
                          },
                          [_vm._v("新 建")]
                        )
                      ])
                    : _vm._e(),
                  _vm.isShowAll &&
                  !(_vm.type == 1 && !_vm.list.length && _vm.isKzqSearchRes)
                    ? _c("Button", { attrs: { type: "default" } }, [
                        _vm._v(
                          _vm._s(_vm.list.length ? "已全部显示" : "暂无数据")
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ]
      ),
      _c(
        "Popup",
        {
          staticClass: "modal-popup",
          attrs: { round: "" },
          model: {
            value: _vm.isShowCreateQuality,
            callback: function($$v) {
              _vm.isShowCreateQuality = $$v
            },
            expression: "isShowCreateQuality"
          }
        },
        [
          _c("div", { staticClass: "modal-box" }, [
            _c("div", { staticClass: "modal-title" }, [
              _vm._v("未找到质检记录")
            ]),
            _c("div", { staticClass: "modal-text" }, [
              _vm._v(
                "是否为编号" +
                  _vm._s(_vm.inputCode) +
                  "的设备创建一条返厂定损记录？"
              )
            ]),
            _c(
              "div",
              { staticClass: "modal-btn" },
              [
                _c(
                  "Button",
                  {
                    staticClass: "btn-item",
                    on: {
                      click: function($event) {
                        _vm.isShowCreateQuality = false
                      }
                    }
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "Button",
                  {
                    staticClass: "btn-item active",
                    attrs: { loading: _vm.qualityLoading },
                    on: { click: _vm.onclickCreateQualityBtn }
                  },
                  [_vm._v("确认创建")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }