require("core-js/modules/es.array.concat");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/icon/style", "vant/es/icon", "@/utils/time", "@/utils/str"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/icon/style"), require("vant/es/icon"), require("@/utils/time"), require("@/utils/str"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.icon, global.time, global.str);
    global.qualityItem = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _icon, _time, _str) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _icon = _interopRequireDefault(_icon);
  var _default2 = {
    name: "QualityItem",
    components: {
      Icon: _icon.default
    },
    filters: {
      getYMD: _time.getYMD,
      filterOrderStatus: _str.filterOrderStatus,
      formateTime: _time.formateTime
    },
    props: {
      item: Object,
      default: function _default() {
        return {};
      }
    },
    data: function data() {
      var ossHost = this.$config.base.ossHost;
      return {
        ossHost: ossHost,
        fType: window.fType,
        isLoss: window.isLoss
      };
    },
    methods: {
      clickItem: function clickItem() {
        var vm = this;
        var path = "/factory/quality/history/".concat(vm.item.uniqueId, "?id=").concat(vm.item.id); // 质检详情

        if (window.fType == 5 && !window.isLoss) {
          path = "/factory/quality/tlQaInfo/".concat(vm.item.id, "?uniqueId=").concat(vm.item.uniqueId, "&time=").concat(vm.item.submitAt, "&name=").concat(vm.item.operatorName);
        }

        vm.$router.push({
          path: path
        });
      }
    }
  };
  _exports.default = _default2;
});